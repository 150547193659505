<template>
  <v-row class="inner-content">
    <v-col>

      <v-card>
        <v-card-title>
          <span>Ad feed</span>

          <v-spacer></v-spacer>
          <GoogleAccountAutoComplete
            v-model="selectedAccountID"
            @changed="accountChanged" 
            @refreshing="accountsRefreshing"
          ></GoogleAccountAutoComplete>

          <v-spacer></v-spacer>
          <v-autocomplete v-model="selectedFeedID" :items="feeds" :loading="loadingRefreshFeeds" item-text="feed_name" item-value="feed_id" label="Feed" @change="feedChanged"
            prepend-icon="rss_feed" required :rules="[v => !!v || 'Feed is required']">
            <template v-slot:append-outer>
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item>
                      <v-list-item-title @click="refreshFeeds()">Refresh feeds</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <span class="text-body-2 ml-2" style="width: 270px;" v-show="selectedFeedID">
                {{ selectedFeedRefreshedAt }}
                <v-tooltip>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">help</v-icon>
                  </template>
                  <span>The time that the feed data shown was last refreshed from the Google API.<br>
                    To refresh the feed data, use the "Refresh feeds" option from the menu on the left.
                  </span>
                </v-tooltip>
              </span>
            </template>
          </v-autocomplete>

          <v-spacer></v-spacer>
        </v-card-title>

        <AggregatedFeedItemsTable :pAccountID="selectedAccountID" :pFeedID="selectedFeedID" :pFeedLastRefreshedAt="selectedFeedRefreshedAt" />
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AdCustomizerAggregateFeeds',

  data () {
    return {
      feeds: [],
      loadingRefreshFeeds: false,
      lsSelectedAccount: 'adCustomizer_selectedAccount',
      lsSelectedFeed: 'adCustomizer_selectedFeed',
      selectedAccountID: 0,
      selectedFeedID: 0,
    }
  },

  computed: {
    feedsEndpoint () {
      return '/a/google/accounts/' + this.selectedAccountID + '/user-feeds?xsort=feed_name&xfields=feed_id,feed_name,items_refreshed_at'
    },

    refreshFeedsEndpoint () {
      return '/a/google/accounts/' + this.selectedAccountID + '/refresh-ad-customizer-feeds'
    },

    selectedFeedRefreshedAt () {
      var dateStr = this.feeds.filter(el => el.feed_id == this.selectedFeedID).map(val => val.items_refreshed_at)[0]
      var dateObj = this.$dateTime.fromSQL(dateStr)
      var diffObj = this.$dateTime.now().diff(dateObj, ['hours', 'minutes']).toObject()
      if (diffObj['hours'] == 0) {
        if (diffObj['minutes'] < 5) {
          return 'Refreshed just now'
        }
        var minsRounded = Math.round(Number.parseFloat(diffObj['minutes']))
        return 'Refreshed ' + minsRounded + ' minutes ago'
      }
      var hoursRounded = Math.round(Number.parseFloat(diffObj['hours']))
      return 'Refreshed ' + hoursRounded + ' hours ago'
    }
  },

  components: {
    AggregatedFeedItemsTable: () => import('@/components/tables/AggregatedFeedItemsTable.vue'),
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
  },

  methods: {
    accountChanged: function () {
      localStorage.setItem(this.lsSelectedAccount, JSON.stringify(this.selectedAccountID))
      this.selectedFeedID = 0
      this.fetchFeeds()
    },

    accountsRefreshing () {
      this.selectedFeedID = 0
      this.feeds = []
    },

    feedChanged: function () {
      if (this.selectedFeedID) {
        localStorage.setItem(this.lsSelectedFeed, JSON.stringify(this.selectedFeedID))
      }
    },

    fetchFeeds: function () {

      this.$http.get(this.feedsEndpoint).then(resp => {
        if (resp.data.data) {
          this.feeds = resp.data.data
        } else {
          this.feeds = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: this.feedsEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally()
    },

    refreshFeeds: function () {
      this.loadingRefreshFeeds = true
      this.selectedFeedID = 0

      this.$http.post(this.refreshFeedsEndpoint).then(resp => {
        this.fetchFeeds()
        this.loadingRefreshFeeds = false
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.refreshFeedsEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loadingRefreshFeeds = false)
    },
  },

  created: function () {

    // if present, use local selected account
    if (localStorage.getItem(this.lsSelectedAccount)) {
      this.selectedAccountID = JSON.parse(localStorage.getItem(this.lsSelectedAccount))
      this.accountChanged()
    }

    // if present, use local selected feed
    if (localStorage.getItem(this.lsSelectedFeed)) {
      this.selectedFeedID = JSON.parse(localStorage.getItem(this.lsSelectedFeed))
    }
},

}
</script>
